// Combined NewUpload.tsx
import React, { useState, useEffect, useContext } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Snackbar, Alert, Typography, CircularProgress, Box } from '@mui/material';
import API from '../../Internals/API';
import { FrontendContext } from '../../Internals/ContextStore';
import { updateLoggedInUserAnimals } from "../User/userUtils";

interface NewUploadProps {
  open: boolean;
  handleClose: (canceled: boolean) => void;
  animalID?: string; // Optional for existing animals
  animalDetails?: { // For new animals or if `animalID` is included here
    animalID?: string;
    animalName: string;
    animalType: string;
    animalDOB?: string;
    dateOfBirth?: string;
  };
  filesToUpload: File[] | null;
  onUploadSuccess?: () => void;
}

export default function NewUpload({ open, handleClose, animalID, animalDetails, filesToUpload, onUploadSuccess }: NewUploadProps) {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean | null>(null);
  const frontendContext = useContext(FrontendContext);
  const userid = frontendContext.user.contextRef.current.userId;

  const handleFileUpload = async () => {
    if (!filesToUpload || filesToUpload.length === 0) {
      setErrorMessage('No files selected for upload.');
      setIsSnackbarOpen(true);
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append('userId', userid);

    const effectiveAnimalID = animalID || animalDetails?.animalID;
    let endpoint = API.Upload();
    if (effectiveAnimalID) {
      formData.append('animalID', effectiveAnimalID);
      endpoint += `/existing`;
      console.log("Uploading to existing animal endpoint:", endpoint, "Animal ID:", effectiveAnimalID);
    } else if (animalDetails) {
      const { animalName, animalType, animalDOB, dateOfBirth } = animalDetails;
      const dob = animalDOB || dateOfBirth;
      if (!animalName || !animalType || !dob) {
        setErrorMessage('Missing animal details. Please provide all required information.');
        setIsSnackbarOpen(true);
        setIsUploading(false);
        return;
      }
      formData.append('animalName', animalName);
      formData.append('animalType', animalType);
      formData.append('dateOfBirth', dob);
      console.log("Uploading new animal with details:", animalDetails);
    } else {
      setErrorMessage('Missing animal identification.');
      setIsSnackbarOpen(true);
      setIsUploading(false);
      return;
    }

    filesToUpload.forEach((file, index) => {
      formData.append('files', file);
      console.log(`File ${index + 1} appended:`, file.name);
    });

    console.log("Final endpoint for request:", endpoint);

    try {
      const response = await fetch(endpoint, { method: 'POST', body: formData });
      console.log("Request sent to:", endpoint);

      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Upload failed. Unsupported file type or invalid data.');
        setIsSnackbarOpen(true);
      } else {
        const data = await response.json();
        console.log('Upload success:', data);

        await updateLoggedInUserAnimals(frontendContext);
        onUploadSuccess && onUploadSuccess();

        setUploadSuccess(true);
        setTimeout(() => {
          handleClose(false);
          setIsUploading(false);
        }, 1000);
      }
    } catch (error) {
      console.error('Upload error:', error);
      setErrorMessage('Upload failed. Please try again.');
      setIsSnackbarOpen(true);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    if (open && filesToUpload && filesToUpload.length > 0) {
      handleFileUpload();
    }
  }, [open, filesToUpload]);

  const handleSnackbarClose = () => setIsSnackbarOpen(false);

  return (
    <>
      <Dialog open={open} onClose={() => handleClose(true)}>
        <DialogTitle>{isUploading ? 'Uploading...' : 'Upload Videos'}</DialogTitle>
        <DialogContent>
          {isUploading ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <CircularProgress />
              <Typography variant="body1" mt={2}>Uploading your video for the animal: {animalDetails?.animalName || ''}</Typography>
              {uploadSuccess && <Typography variant="body1" mt={2} color="success.main">Upload Successful!</Typography>}
            </Box>
          ) : (
            <Typography variant="body1">Preparing to upload your videos...</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)} disabled={isUploading}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>{errorMessage}</Alert>
      </Snackbar>
    </>
  );
}
